body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* h1, h2, h3, h4, h5, h6 styling*/

h2 {
  font-size: 30px;
}
/* Start generale settings */

.section { 
  padding: 50px 20px;
}
.bg-white {
  background-color: white;
}
.max-width {
  max-width: 1200px;  
}
.flex {
  display: flex;
}
.flex-center {
  justify-content: center;
}
.flex-middle {
  align-items: center;
}
.full-height {
  height: 100vh;
}
.center {
  text-align: center;
}
.flex-50 {
  width: 50%;
}
.service-li h3 {
  font-size: 20px;
}
h3, h2 {
  line-height: 1.2;
  padding-right: 5px;
  background-image: linear-gradient(108deg, #b16cea 8%, #ff5e69 40%, #ff8a56 77%, #ffa84b 91%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.herobanner-wrraper h2 {
    font-size: 35px;
    @media screen and (max-width: 800px) {
      font-size: 30px;
    }
}
.button { 
  text-decoration: none;
    padding: 10px 15px;
    color: #3D3C3C;
    font-weight: 600;
    margin-right: 10px;
    text-transform: uppercase;
    border: 1px solid #aba2a2;
    border-radius: 0px;
}
ul.navul {
  list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0px;
}
ul.navul li a {
  text-decoration: none;
  font-weight: 500;
}
ul.navul li {
  padding: 0px 15px;
  a {
    color: black;
    &:hover {
      color: blue;
    }
  }
}
.max-width {
  max-width: 1200px;
  margin: auto;
}


/* The Nav starts here  */

.navsection .nav-wrraper{
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
.logowrraper img {
  max-width: 220px;
}
.social-icons-wrapper span {
  padding: 5px;
}
.social-icons-wrapper {
  margin-top: 15px;
}
p {
  color: grey;
  font-family: system-ui;
}
li {
  color: grey;
}
.Skills-section, .Reviews-section, .footer-section, .sideprojects-section, .bggrey{
  background-color: #f8f8f8;
}
.footer-wrraper img {
  max-width: 180px;
}
.footer-section {
  padding-bottom: 15px;
}
.footer-wrraper img {
  margin: 20px 0px;
}
/* hero banner starts here */

.herBanner-section {
    background: linear-gradient(270deg,#fff,#f4ff99 24.38%,#e3d4f9 77.05%,#fff);
    
      padding-bottom: 0px;
  
}


.herobanner-wrraper  {
align-items: center;
}
.center {
  text-align: center;
}

.languages-wrraper img {
  width: 100%;
}
.navsection {
  border-bottom: 1px solid #dedada;
}
/* About starts here  */
.about-wrraper {
  align-items: center;
}
.about-wrraper>div {
  padding: 5px 15px;
}
ul.servicesul li {
  margin-top: 10px;
  background-image: linear-gradient(108deg, #e2ccf4 8%, #f6c7ca 40%, #f6d3c3 77%, #f1d6b9 91%);
  padding: 2px;
  border-radius: 5px;
  list-style: none;
  max-width: 500px;
}

.service-li {
  background: white;
  border-radius: 5px;
  padding: 15px; 
                      }
        .service-li h3, .service-li p {
    padding: 0px;
    margin: 0px;
        }
        .post-container {
          max-width: 800px;
          margin: auto;
        }
        .btn-container {
          margin-top: 40px;
        }
        .secondary-button {
          text-decoration: none;
    padding: 5px 10px;
    color: #3D3C3C;
    font-weight: 600;
    margin-right: 10px;
    text-transform: uppercase;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #aba2a2;
          &:hover {
           color: black;
          }
      }
.blogpost-section .title-wrraper {
        padding-bottom: 50px;
    }
/* portfoio starts here  */
.portfolio-wrraper, .sideprojects-wrraper, 
.Reviews-wrraper, .blog-wrraper, .Skills-wrraper  {

flex-wrap: wrap;
}
.portfolio-33, .Reviews-col1, .Skills-col1 {
  width: 400px;
}
.portfolio-33 img {
  width: 100%;
  border: 1px solid #d1e6df;
}
.portfolio-list, .sideprojects-list, .blog-list {
  padding: 10px;
  margin-top: 20px;
}
.used-skill {
  display: flex;
  flex-wrap: wrap;
}
.used-skill span {
  background-color: #f8f8f8;
    border-radius: 10px;
    font-size: 13px;
    padding: 2px 10px;
    margin-right: 5px;
    margin-top: 7px;
    color: gray;
}
ol li {
  
  margin-bottom: 20px;
}
.nightmode {
  
  background-color: black;
  .section {
    background: black;
    background-color: black;
    border-bottom: 1px solid #1c1c1c;
  }
  .navul li a, .p-title {
    color: #fff;
}
.service-li {
  background: #000;
 
}

}
.p-title {
  color: black;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0px;
}

/*  Reviews starts here  */ 
.Reviews-list {
  border-style: solid;
    border-width: 1px;
    border-color: #C6C2C2;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 50px 0px 40px 0px px;

  padding: 20px 20px;
  margin: 8px;
  border-radius: 5px;
  text-align: center;
}

.review33 img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 7px;
}
/* Contact starts here */
.contact-wrraper {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #C6C2C2;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 40px 0px;
}

@media screen and (max-width:800px) {
  .herobanner-wrraper, .about-wrraper {
    display: block;

}
.flex-50 {
  width: 100%;
}
.imagegrupehero img, .about-wrraper img {
  width: 100%;
}
.servicesul {
  padding-left: 0rem;
}
.Skills-wrraper {
  padding: 0px 20px;
}
.contact-wrraper {
  padding: 20px;
}
.menuwrraper, .buttonswrraper {
  display: none;
}
.section {
  padding: 50px 15px;
}
.about-wrraper > div {
  padding: 5px 0px;
}
.portfolio-list, .sideprojects-list, .blog-list {
  padding: 5px 0px;
}
}